<template>
    <div id="editAssetCategoriesList" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="editAssetCategory"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <app-sitetitle subHeaderDescKey="asset_editCategorySubTitle"></app-sitetitle>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!--begin::Portlet -->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon kt-hidden">
                                                    <i class="la la-gear"></i>
                                                </span>
                                                <h3 class="kt-portlet__head-title">{{ $t("asset_editCategory") }}</h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section kt-section--last">

                                                    <h3 class="kt-section__title">1. {{ $t("asset_editCategoryInfo") }}</h3>

                                                    <div class="kt-section__body">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group validated">
                                                                    <label for="editCategoryForm_nameInput">{{ $t("asset_categoryName") }} *</label>
                                                                    <div class="input-group">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                        </div>
                                                                        <input maxlength="50" v-model="vName" @input="$v.vName.$touch()" @keyup="onChangeForm" type="text" class="form-control" id="editCategoryForm_nameInput" :placeholder="$t('common_enterName')" disabled />
                                                                        <div v-if="!$v.vName.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                        <div v-else-if="!$v.vName.minLen" class="invalid-feedback">{{ $t("error_minLengthMsg", [$v.vName.$params.minLen.min]) }}</div>
                                                                    </div>
                                                                    <span v-if="isDefault" class="form-text text-muted">&nbsp;</span>
                                                                    <span v-else class="form-text text-muted"> {{ $t("asset_categoryNameExample") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group" v-show="isImageLoaded || isDefault">
                                                                    <label>{{ $t("asset_categoryIconSizeMap") }} *</label>
                                                                    <select id="editCategoryForm_iconSizeRadio" class="form-control kt-select2">
                                                                        <!-- To display placeholder -->
                                                                        <option></option>
                                                                    </select>
                                                                    <span class="form-text text-muted">{{ $t("asset_categoryIconSizedetailInfo") }}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row form-group">
                                                            <div class="col-md-6">
                                                                <div>
                                                                    <div class="row" style="margin-left: 0px;">
                                                                        <label class="col-form-label mr-3" id="peopleCategoryStatusLabel">{{ $t("asset_categoryIsItPeople") }}</label>
                                                                        <div>
                                                                            <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--primary">
                                                                                <label>
                                                                                    <input type="checkbox" checked="checked" id="isPeopleCategoryCheckbox" disabled v-model="isPeopleCategory" @change="onChangeIsPeopleCategory">
                                                                                    <span></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                        <label class="col-form-label ml-1" id="peopleCategoryStatusMessage">{{ peopleCategoryStatusMessage }}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="form-group row">
                                                            <div v-if="!isDefault" class="col-lg-6 validated">
                                                                <label>{{ $t("asset_categoryFileBrowser") }} *</label>
                                                                <div class="custom-file">
                                                                    <input type="file" @change="handleFileSelected" ref="imageAssetFile" :lang="this.$i18n.locale" class="custom-file-input cursorPointer" accept="image/gif, image/jpeg, image/png" id="selectAssetImageInputFile" />
                                                                    <label class="custom-file-label textLeft" id="selectAssetImageInputLabel" for="customFile">{{ $t("common_chooseFile") }}</label>
                                                                    <div v-if="!$v.imageName.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                    <span class="form-text text-muted">{{ $t("site_selectImageHelpText") }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-1" v-show="isImageLoaded" />
                                                            <div v-if="isImageLoaded" class="col-lg-5">
                                                                <div id="editCategoryForm_imagePreview" class="iconPreviewEdit" v-bind:style="{ backgroundImage: 'url(' + this.image + ')' }"></div>
                                                                <div class="kt-margin-b-10 kt-margin-t-10">{{ imageName }}</div>
                                                                <div>
                                                                    <button id="newAssetRemoveImageButton" type="button" @click="onRemoveImage" class="btn btn-outline-brand">{{ $t("common_remove") }}</button>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="imageName" class="row col-md-6" style="margin-top: -10px; margin-bottom: -10px; margin-left: 0px;">
                                                                <label for="example-text-input" class="col-form-label mr-3" style="padding-top: 13px;">{{ $t("asset_categoryFileBrowser") }} *</label>
                                                                <div class="ml-1">
                                                                    <div id="editCategoryForm_imagePreview" class="iconPreviewCustomEdit" v-bind:style="{ backgroundImage: 'url(' + this.image + ')' }"></div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 v-if="originalName == 'ASSET_C_PATIENTS' && isFrench" class="kt-section__title">2. {{ $t("asset_editSubcategoriesInfoPatient") }}</h3>
                                                    <h3 v-else class="kt-section__title">2. {{ $t("asset_editSubcategoriesInfo") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                <span v-if="originalName == 'ASSET_C_PATIENTS' && isFrench">{{ $t("asset_subcategoriesHelpL1Patient") }}</span>
                                                                <span v-else>{{ $t("asset_subcategoriesHelpL1") }}</span>
                                                                <ul>
                                                                    <li v-if="originalName == 'ASSET_C_PATIENTS' && isFrench">{{ $t("asset_subcategoriesHelpL2Patient") }}</li>
                                                                    <li v-else>{{ $t("asset_subcategoriesHelpL2") }}</li>
                                                                    <li v-if="originalName == 'ASSET_C_PATIENTS' && isFrench">{{ $t("asset_subcategoriesHelpL3Patient") }}</li>
                                                                    <li v-else>{{ $t("asset_subcategoriesHelpL3") }}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="kt-form__section kt-form__section--first">
                                                                <div id="kt_repeater_subcategory">
                                                                    <div class="form-group row">
                                                                        <div data-repeater-list="kt_repeater_subcategory-list" class="col-lg-6">
                                                                            <div data-repeater-item class="kt-margin-b-10">
                                                                                <div class="input-group">
                                                                                    <input type="text" name="text-name" class="form-control" :placeholder="$t('common_enterName')" />
                                                                                    <input type="text" name="text-id" class="form-control" disabled="disabled" style="display:none;" />
                                                                                    <div class="input-group-append">
                                                                                        <a href="javascript:;" data-repeater-delete class="btn btn-danger btn-icon">
                                                                                            <i class="la la-close"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <div id="editAssetCategoryCreateSubCategoryButton" data-repeater-create="" class="btn btn btn-brand marginTM30">
                                                                                <span>
                                                                                    <i class="la la-plus"></i>
                                                                                    <span v-if="originalName == 'ASSET_C_PATIENTS' && isFrench">{{ $t("asset_addSubcategoryPatient") }}</span>
                                                                                    <span v-else>{{ $t("asset_addSubcategory") }}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>

                                                    <h3 class="kt-section__title">3. {{ $t("asset_editCustomFieldsInfo") }}</h3>

                                                    <div class="kt-section__body">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                <span>{{ $t("asset_customfieldsHelpL1") }}</span>
                                                                <ul>
                                                                    <li>{{ $t("asset_customfieldsHelpL2") }}</li>
                                                                    <li>{{ $t("asset_customfieldsHelpL3") }}</li>
                                                                    <li>{{ $t("asset_customfieldsHelpL4") }}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="form-group">
                                                            <div class="kt-form__section kt-form__section--first">
                                                                <div id="kt_repeater_customfield">
                                                                    <div class="form-group row validated">
                                                                        <label class="col-lg-4 col-form-label">{{ $t("common_name") }}</label>
                                                                        <label class="col-lg-3 col-form-label">{{ $t("common_type") }}</label>
                                                                        <label class="col-lg-5 col-form-label">{{ $t("common_enumeration") }}</label>
                                                                        <div data-repeater-list="kt_repeater_customfield-list" class="col-lg-12">
                                                                            <div v-for="(item, index) in customFields" :key="index" class="form-group  row kt-margin-b-10">
                                                                                <div class="col-lg-4">
                                                                                    <div class="input-group">
                                                                                        <input v-if="item.isDefault" type="text" name="text-id" :value="item.name" class="form-control" disabled="disabled" />
                                                                                        <input v-else type="text" name="text-name" @keyup="onChangeForm" v-model="item.name" class="form-control" :placeholder="$t('common_enterName')" />
                                                                                        <div v-if="!item.isDefault" class="input-group-append">
                                                                                            <a href="javascript:;" @click="removeCustomFieldItem(index)" class="btn btn-danger btn-icon">
                                                                                                <i class="la la-close"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div v-if="!$v.customFields.$each[index].name.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <div class="input-group">
                                                                                        <input type="text" name="text-type" :value=" item.typec && $t('common_' + item.typec.toLowerCase())" :data-id="item.id" class="form-control" style="display:none;" disabled="disabled" />
                                                                                        <select name="select-type" :id="'select_type_select_'+ index" class="form-control kt-select2" style="display:none;">
                                                                                            <option></option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-5">
                                                                                    <div class="input-group" style="display:none;">
                                                                                        <select name="select-enums" :data-enum="index" multiple="multiple" class="form-control kt-select2" :disabled="item.isDefault"> </select>
                                                                                    </div>
                                                                                    <div class="input-group validated" style="display:none;">
                                                                                        <input type="text" @keyup="onChangeForm" v-model="item.url" name="text-URL" :data-url="index" class="form-control" :placeholder="$t('common_enterURL')" />
                                                                                    </div>
                                                                                    <div v-if="!$v.customFields.$each[index].url.required" class="invalid-feedback">{{ $t("error_fieldIsRequired") }}</div>
                                                                                    <div v-if="isValidURLs[index] === false" class="invalid-feedback">{{ $t("error_fieldURLInvalid") }}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            <div id="editAssetCategoryCreateCustomFieldButton" @click="addCustomFieldItem()" class="btn btn btn-brand marginTM30">
                                                                                <span>
                                                                                    <i class="la la-plus"></i>
                                                                                    <span>{{ $t("asset_addCustomfield") }}</span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions kt-form__actions--right">
                                                    <button v-if="$v.$invalid || !isFormDataChanged || isInvalidateURLs" id="editAssetCategoryUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged || isInvalidateURLs">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button v-else id="editAssetCategoryUpdateButton" @click="onUpdateButton" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged || isInvalidateURLs">
                                                        <i class="la la-save"></i>
                                                        {{ $t("common_saveChanges") }}
                                                    </button>
                                                    <button id="editAssetCategoryCancelButton" @click="onCancelButton" type="button" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet Category -->
                                </div>
                            </div>
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import SiteTitle from "../site/widgets/sitetitle.vue";
import i18n from "../../i18n";
import commonVueHelper from "../../helpers/commonVueHelper";

const _iconSizes = require("../../constants/iconSizes");

export default {
    data() {
        console.log("Component(EditCategory)::data() - called");
        return {
            siteId: this.$route.params.siteId,
            assetCategoryId: this.$route.params.assetCategoryId,
            iconSizes: _iconSizes.list,
            iconSizeInPixel: {},
            customFieldTypesData: [],
            isDefault: false,
            vName: "",
            originalName: "",
            vIconSize: "",
            image: "",
            imageName: "",
            isImageLoaded: false,
            isFormDataChanged: false,
            isRepeaterInitList: {},
            isFrench: this.$i18n.locale === "fr" ? true : false,
            isPeopleCategory: false,
            peopleCategoryStatusMessage: i18n.t("common_no"),
            customFields: [],
            isValidURLs: [],
            isInvalidateURLs: false
        };
    },
    created: function() {
        console.log("Component(EditCategory)::created() - called");
        const payload = {
            siteId: this.siteId,
            assetCategoryId: this.assetCategoryId
        };
        this.getAssetCategoryById(payload);
        for (let i = 0; i < this.iconSizes.length; i++) {
            let pixelSize = "16px";
            switch (this.iconSizes[i].toLowerCase()) {
                case "small":
                    pixelSize = "24px"; //"16px";
                    break;
                case "medium":
                    pixelSize = "48px";
                    break;
                case "large":
                    pixelSize = "96px";
                    break;
                default:
            }
            this.iconSizeInPixel[this.iconSizes[i]] = pixelSize;
        }
        this.customFieldTypesData = commonVueHelper.getCustomFieldTypesData();
    },
    mounted: function() {
        console.log("Component(EditCategory)::mounted() - Init metronic layout");
        KTLayout.init();
        this.initIconSizeSelect2();
    },
    destroyed: function() {
        console.log("Component(EditCategory)::destroyed() - called");
        this.resetCategoriesState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        vName: {
            required,
            minLen: minLength(2)
        },
        vIconSize: {
            required
        },
        imageName: {
            required
        },
        customFields: {
            required,
            $each: {
                name: {
                    required: requiredIf(function(customFiled) {
                        return customFiled.typec === "URL";
                    })
                },
                url: {
                    required: requiredIf(function(customFiled) {
                        return customFiled.typec === "URL";
                    })
                }
            }
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentAssetCategory: function(category) {
            console.log("Component(EditCategory)::watch(currentAssetCategory) - called with ", category);
            if (category) {
                this.vName = category.name;
                this.originalName = category.name;
                this.isDefault = category.isDefault;
                this.vIconSize = category.iconSize;
                this.imageName = category.iconName;
                this.image = category.iconImg;
                this.isPeopleCategory = category.isPeopleCategory;
                this.peopleCategoryStatusMessage = category.isPeopleCategory ? i18n.t("common_yes") : i18n.t("common_no");
                if (category.isDefault) {
                    this.isImageLoaded = false;
                    if (category.name) {
                        this.vName = i18n.t(category.name);
                    }
                } else {
                    this.isImageLoaded = true;
                }

                if ($("#editCategoryForm_iconSizeRadio").data("select2")) {
                    let iconSize = this.vIconSize;
                    $("#editCategoryForm_iconSizeRadio").val(iconSize);
                    // Trigger change event and provide an additional param
                    $("#editCategoryForm_iconSizeRadio").trigger({ type: "change", params: { initialInit: true } });
                }

                // Setup the repeater for subcategories
                let subcategoriesRepeater = this.initRepeater("kt_repeater_subcategory", "text-id", "text-name");
                //Set the values of the inputs as a formatted object
                let textInputSubcategoryList = [];
                if (category.subcategories) {
                    for (let i = 0; i < category.subcategories.length; i++) {
                        let subcategoryName = category.subcategories[i].name;
                        if (category.subcategories[i].isDefault) {
                            subcategoryName = i18n.t(subcategoryName);
                        }
                        let repeaterVal = {
                            "text-name": subcategoryName,
                            "text-id": category.subcategories[i].id
                        };
                        textInputSubcategoryList.push(repeaterVal);
                    }
                }
                textInputSubcategoryList.push({ "text-name": undefined });
                subcategoriesRepeater.setList(textInputSubcategoryList);
                this.isRepeaterInitList["kt_repeater_subcategory"] = true;

                // Setup the repeater for custom fields
                // let customFieldsRepeater = this.initRepeater("kt_repeater_customfield", "text-id", "text-name", "text-type", "select-type", "select-enums");
                // Set the values of the inputs as a formatted object
                // let textInputCustomFieldList = [];
                if (category.customFields) {
                    for (let k = 0; k < category.customFields.length; k++) {
                        let customfieldName = category.customFields[k].name;
                        if (category.customFields[k].isDefault && customfieldName) {
                            customfieldName = i18n.t(customfieldName);
                        }

                        let customFieldItem = {
                            id: category.customFields[k].id,
                            name: customfieldName,
                            typec: category.customFields[k].typec,
                            isDefault: category.customFields[k].isDefault,
                            isStatic: category.customFields[k].isStatic
                        }
                        if (customFieldItem.typec === "Enum") {
                            customFieldItem.enumc = category.customFields[k].enumc;
                        } else if (customFieldItem.isStatic) {
                            if (customFieldItem.typec === "URL") {
                                customFieldItem.url = category.customFields[k].staticValue;
                            }
                        }
                        this.customFields.push(customFieldItem);
                    }
                }
                // Add an empty field to display
                this.customFields.push({
                    name: "",
                    isDefault: false,
                    typec: "",
                    enumc: [],
                    url: ""
                });

                setTimeout(() => {
                    for (let i = 0; i < this.customFields.length; i++) {
                        if(this.customFields[i].isDefault) {
                            let textTypeSelector = $("input[name='text-type'][data-id='" + this.customFields[i].id + "']");
                            textTypeSelector.show();
                        } else {
                            let selectTypeSelector = $("#select_type_select_" + i);
                            this.initTypeSelect2(selectTypeSelector, this.customFields[i].typec, i);
                        }
                        let enumData = this.customFields[i].enumc ? [...this.customFields[i].enumc] : [];
                        let tagsEnumSelector = $("select[name='select-enums'][data-enum*='" + i + "']");
                        if (this.customFields[i].typec === "Enum" && enumData && enumData.length > 0) {
                            if (this.customFields[i].isDefault) {
                                // Translate the enums key
                                for (let l = 0; l < enumData.length; l++) {
                                    enumData[l] = i18n.t(enumData[l]);
                                }
                            }
                            this.initEnumsSelect2(tagsEnumSelector, enumData, i);
                            tagsEnumSelector.parent().show();
                        } else {
                            tagsEnumSelector.parent().hide();
                        }
                    }
                }, 50);

                // customFieldsRepeater.setList(textInputCustomFieldList);
                // this.isRepeaterInitList["kt_repeater_customfield"] = true;

                // this.registerEventsOnRepeater(this, $(":input[name*='[text-name]']"));
            }
        },

        image: function(img) {
            if (img) {
                let i = new Image();
                i.src = img;
            }
        },

        // Watch the changed of user language
        user: function(user) {
            console.log("Component(EditCategory)::watch(user) - called with ", user);
            if (user) {
                if (this.isPeopleCategory) {
                    this.peopleCategoryStatusMessage = i18n.t("common_yes");
                } else {
                    this.peopleCategoryStatusMessage = i18n.t("common_no");
                }
                // Translate icon file browser
                commonVueHelper.resetImageInputLabel(this.isImageLoaded);
                // Translate image size select2
                this.updateIconSizeSelect2();
                // Translate all subcategories input name placeholder
                this.translateRepeaterInputTextNamePlaceholder("kt_repeater_subcategory", "text-name");
                // Translate all custom field input name placeholder
                this.translateRepeaterInputTextNamePlaceholder("kt_repeater_customfield", "text-name");
                // Translate all custom field input url placeholder
                this.translateRepeaterInputTextURLPlaceholder("kt_repeater_customfield", "text-URL");
                // Translate all custom field select multi values placeholder
                this.translateRepeaterSelectValuesPlaceholder();
                // Translate all custom field type select2
                this.translateRepeaterSelectType();

                for (let i = 0; i < this.customFields.length; i ++) {
                    if(!this.customFields[i].isDefault) {
                        const selectTypeSelector = $("#select_type_select_" + i);
                        this.initTypeSelect2(selectTypeSelector, this.customFields[i]["typec"], i);
                    }
                }
            }

        },
        customFields: {
            handler(customFields) {
                for(let i = 0; i < customFields.length; i++) {
                    let res;
                    if (customFields[i]["typec"] === "URL" && customFields[i]["url"]) {
                        res = customFields[i]["url"].match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
                    }
                    if (res === null && customFields[i]["typec"] === "URL" && customFields[i]["url"] !== "") {
                        this.isValidURLs[i] = false;
                    } else {
                        this.isValidURLs[i] = true;
                    }
                }
                this.isInvalidateURLs = this.isValidURLs.find(item => item === false) !== undefined ? true : false;
            },
            deep: true,
        }

    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentAssetCategory", "user"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getAssetCategoryById", "resetCategoriesState", "updateAssetCategory", "sendNotification"]),

        addCustomFieldItem() {
            if (!this.customFields) {
                this.customFields = [];
            }
            this.customFields.push({
                name: "",
                isDefault: false,
                typec: "",
                enumc: [],
                url: ""
            });
            const index = this.customFields.length - 1;
            setTimeout(() => {
                const selectTypeSelector = $("#select_type_select_" + index);
                this.initTypeSelect2(selectTypeSelector, null, index);
            }, 50);
            this.onChangeForm();
        },
        removeCustomFieldItem(index) {
            if (confirm(i18n.t("common_deleteEltConfirmText"))) {
                this.customFields.splice(index, 1);
                this.onChangeForm();
            }
        },

        updateAssociatedListCustomField(customFields) {
            const associatedList = [];
            let listByName = {};
            let data = {};
            let dataList =  this.currentAssetCategory.customFields;
            for (let i = 0; i < customFields.length; i++) {
                let id = customFields[i].id;
                let name = customFields[i].name;
                if (name) {
                    let item = {
                        name: name,
                        isDefault: false
                    };
                    if (id && dataList) {
                        data = _.find(dataList, ["id", id]);
                        if (data.isDefault) {
                            item.isDefault = true;
                            item.name = data.name; // Set the subcategory key instead the displayed translation
                            if (data.typec) {
                                item.typec = data.typec;
                            }
                            if (data.enumc) {
                                item.enumc = data.enumc;
                            }
                        }
                        item.id = id;
                    }
                    if (!item.isDefault) {
                        let typec = customFields[i].typec;
                        if (typec) {
                            item.typec = typec;
                            if (typec === "Enum") {
                                item.enumc = customFields[i].enumc;
                            
                                if (!item.enumc || item.enumc.length === 0) {
                                    this.sendNotification({
                                        text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                        type: "error"
                                    });
                                    return null;
                                }
                            } else if (typec === "URL") {
                                item.staticValue = customFields[i].url;
                                if (!item.staticValue) {
                                    this.sendNotification({
                                        text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                        type: "error"
                                    });
                                    return null;
                                }
                            } else {
                                item.enumc = null;
                            }
                        }
                    }
                    associatedList.push(item);

                    // Check duplicate name
                    if (listByName[name]) {
                        // name already defined
                        let key_duplicated = "common_duplicatedEltName";
                        switch (repeaterId) {
                            case "kt_repeater_subcategory":
                                key_duplicated = "asset_duplicatedSubcategoryName";
                                break;
                            case "kt_repeater_customfield":
                                key_duplicated = "asset_duplicatedCustomFieldName";
                                break;
                        }
                        this.sendNotification({
                            text: i18n.t(key_duplicated, { name: name }),
                            type: "error"
                        });
                        return null;
                    }
                    listByName[name] = true;
                }
            }
            return associatedList;
        },

        // Translate input name placeholder
        translateRepeaterInputTextNamePlaceholder(repeaterId) {
            const inputTextNameSelectors = $("#" + repeaterId).find("input[name$='[text-name]']");
            for (let inputTextNameSelector of inputTextNameSelectors) {
                if (inputTextNameSelector.placeholder) {
                    inputTextNameSelector.placeholder = i18n.t("common_enterName");
                }
            }
        },

        translateRepeaterInputTextURLPlaceholder(repeaterId) {
            const inputTextURLSelectors = $("#" + repeaterId).find("input[name$='[text-URL]']");
            for (let inputTextURLSelector of inputTextURLSelectors) {
                if (inputTextURLSelector.placeholder) {
                    inputTextURLSelector.placeholder = i18n.t("common_enterURL");
                }
            }
        },

        // Translate select2 of custom fields type
        translateRepeaterSelectType() {
            const previousTypesData = [...this.customFieldTypesData];
            this.customFieldTypesData = commonVueHelper.getCustomFieldTypesData();
            const inputTypeSelectors = $("#kt_repeater_customfield").find("input[name$='[text-type]']");
            const selectTypeSelectors = $("#kt_repeater_customfield").find("select[name*='[select-type]']");
            for (let i=0; i<selectTypeSelectors.length; i++) {
                if ($(selectTypeSelectors[i]).css('display') !== 'none') {
                    // Translate the select2 type
                    this.updateTypeSelect2($(selectTypeSelectors[i]));
                } else {
                    // Type cannot be changed => Translate the disabled input type
                    const oldTypeData = previousTypesData.find(item => item.text ===  $(inputTypeSelectors[i]).val());
                    if (oldTypeData && oldTypeData.id) {
                        const newTypeData = this.customFieldTypesData.find(item => item.id ===  oldTypeData.id);
                        if (newTypeData && newTypeData.text) {
                            $(inputTypeSelectors[i]).val(newTypeData.text);
                        }
                    }
                }
            }
        },

        // Translate all custom field select multi values placeholder
        translateRepeaterSelectValuesPlaceholder() {
            let selectSelectors = $("#kt_repeater_customfield input.select2-search__field");
            for (let selectSelector of selectSelectors) {
                if (selectSelector.placeholder) {
                    selectSelector.placeholder = i18n.t("common_enterValues");
                }
            }
        },

        // Function called when user change the is people category switcher
        onChangeIsPeopleCategory() {
            if (this.isPeopleCategory) {
                this.peopleCategoryStatusMessage = i18n.t("common_yes");
            } else {
                this.peopleCategoryStatusMessage = i18n.t("common_no");
            }
            this.onChangeForm();
        },

        // Function called to init icon size select2
        initIconSizeSelect2() {
            let self = this;
            let dataIconSize = commonVueHelper.getIconSizesData();
            // Init unit Select2
            commonVueHelper.destroySelect2($("#editCategoryForm_iconSizeRadio"));
            $("#editCategoryForm_iconSizeRadio")
                .select2({
                    placeholder: i18n.t("asset_selectIconSize"),
                    width: "100%",
                    data: dataIconSize,
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.vIconSize = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        updateIconSizeSelect2() {
            let self = this;
            $("#editCategoryForm_iconSizeRadio")
                .empty()
                .select2({
                    placeholder: i18n.t("asset_selectIconSize"),
                    data: commonVueHelper.getIconSizesData()
                })
                .val(self.vIconSize)
                .trigger("change")
        },

        // Function called when user select an image
        handleFileSelected() {
            console.log("Component(NewAsset)::handleFileSelected() - called");
            if (this.$refs.imageAssetFile.files.length > 0) {
                if (this.$refs.imageAssetFile.files[0].size > 3145728) {
                    // 3 MB max
                    alert(i18n.t("common_fileTooBig", { fileLimit: "3" }));
                    this.image = "";
                    this.imageName = "";
                    this.isImageLoaded = false;
                } else {
                    let imageFile = this.$refs.imageAssetFile.files[0];
                    this.imageName = imageFile.name;
                    let reader = new FileReader();
                    reader.readAsDataURL(imageFile);
                    reader.onload = () => {
                        this.image = reader.result;
                        this.isImageLoaded = true;
                        if (!this.vIconSize) {
                            this.vIconSize = this.iconSizes[2]; // "Large"
                        }
                        this.onChangeForm();
                    };
                }
            }
        },

        // Function called when user click on the "Remove" button to remove image
        onRemoveImage() {
            this.image = "";
            this.imageName = "";
            this.isImageLoaded = false;
            this.onChangeForm();
            // Force clear of input file to be able to select the same image again
            $("#selectAssetImageInputFile").val(null);
            // Replace removed file path label by common_chooseFile text
            commonVueHelper.resetImageInputLabel();
        },

        // Function called when user click on the "Cancel" button
        onCancelButton() {
            console.log("Component(EditCategory)::onCancelButton() - called");
            this.$router.push({ name: "assetCategories", params: { fromAction: "cancelButton" } });
        },

        onUpdateButton() {
            console.log("Component(EditCategory)::onUpdateButton() - called");
            let data = {
                siteId: this.siteId,
                assetCategoryId: this.assetCategoryId,
                isDefault: this.isDefault,
                name: this.vName,
                iconSize: this.vIconSize,
                isPeopleCategory: this.isPeopleCategory
            };
            if (this.isDefault) {
                data.name = this.currentAssetCategory.name; // Get the category KEY name instead the translation this.vName
            }
            if (this.imageName) {
                data.iconName = this.imageName;
            }
            if (this.image) {
                data.iconImg = this.image;
            }
            data.subcategories = this.updateAssociatedListWithRepeater("kt_repeater_subcategory", "text-id", "text-name");
            data.customFields = this.updateAssociatedListCustomField(this.customFields);
            if (data.subcategories && data.customFields) {
                this.updateAssetCategory(data);
            }
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        },

        // Initialize a repeater (use to add and remove a repeatable group of input elements)
        initRepeater(repeaterId, repeaterTextIdKey, repeaterTextNameKey, repeaterTextTypeKey, repeaterSelectTypeKey, repeaterSelectEnumsKey) {
            let mySelf = this; // WARNING !!! Do not use "self" name, because it's overwritten by repeater  callback
            let dataById = {};
            let prop = "subcategories";
            if (repeaterId === "kt_repeater_customfield") {
                prop = "customFields";
            }
            // Set the values of tcustom fields by id
            if (mySelf.currentAssetCategory[prop]) {
                for (let n = 0; n < mySelf.currentAssetCategory[prop].length; n++) {
                    let item = mySelf.currentAssetCategory[prop][n];
                    dataById[item.id] = item;
                }
            }

            let myRepeater = $("#" + repeaterId).repeater({
                // start with an empty list of repeaters. Set your first (and only)
                // "data-repeater-item" with style="display:none;" and pass the
                // following configuration flag
                initEmpty: false,

                // "show" is called just after an item is added.  The item is hidden
                // at this point.  If a show callback is not given the item will
                // have $(this).show() called on it.
                show: function() {
                    let currentRepeaterVal = $(this).repeaterVal();
                    let id = currentRepeaterVal[repeaterId + "-list"][0][repeaterTextIdKey];
                    let defaultData = false;
                    let typeData = null;
                    let enumData = null;
                    if (id) {
                        defaultData = dataById[id].isDefault;
                        typeData = dataById[id].typec;
                        if (dataById[id].enumc) {
                            enumData = [...dataById[id].enumc]; // Clone dataById[id].enumc array (to avoid overwrite element properties)
                        }
                    }
                    if (repeaterTextTypeKey && repeaterSelectTypeKey && repeaterSelectEnumsKey) {
                        // Custom fields repeater
                        // Show custom field type as text (in case of default field) or show a select form to choose the type
                        let selectTypeSelector = $(this).find("select[name$='" + "[" + repeaterSelectTypeKey + "]" + "']");
                        let tagsenumSelector = $(this).find("select[name*='[" + repeaterSelectEnumsKey + "]']");
                        let textTypeSelector = $(this).find("input[name$='" + "[" + repeaterTextTypeKey + "]" + "']");
                        // Display type
                        textTypeSelector.hide();
                        selectTypeSelector.hide();
                        if (defaultData) {
                            // Show custom field type value and disable the input
                            textTypeSelector.show();
                        } else {
                            // Show custom field type select form
                            mySelf.initTypeSelect2(selectTypeSelector, typeData);
                            selectTypeSelector.show();
                        }
                        // Display enumerations
                        if (typeData === "Enum" && enumData && enumData.length > 0) {
                            if (defaultData) {
                                // Translate the enums key
                                for (let l = 0; l < enumData.length; l++) {
                                    enumData[l] = i18n.t(enumData[l]);
                                }
                            }
                            mySelf.initEnumsSelect2(tagsenumSelector, enumData);
                            tagsenumSelector.parent().show();
                        } else {
                            tagsenumSelector.parent().hide();
                        }
                    }
                    if (mySelf.isDefault) {
                        // Disable input and hide delete button for default attribute
                        if (defaultData) {
                            // Disable all inputs
                            $(this)
                                .find("input")
                                .attr("disabled", "disabled");
                            $(this)
                                .find("select")
                                .attr("disabled", "disabled");
                            // Hide delete button
                            $(this)
                                .find(".input-group-append")
                                .hide();
                        }
                    }
                    // Check if it's a new added item
                    if (mySelf.isRepeaterInitList[repeaterId]) {
                        mySelf.translateRepeaterInputTextNamePlaceholder(repeaterId, repeaterTextNameKey);
                        // register new input event
                        let textNameSelector = $(this).find("input[name$='" + "[" + repeaterTextNameKey + "]" + "']");
                        mySelf.registerEventsOnRepeater(mySelf, textNameSelector);
                    }
                    $(this).slideDown();
                },

                // "hide" is called when a user clicks on a data-repeater-delete
                // element.  The item is still visible.  "hide" is passed a function
                // as its first argument which will properly remove the item.
                // "hide" allows for a confirmation step, to send a delete request
                // to the server, etc.  If a hide callback is not given the item
                // will be deleted.
                hide: function(deleteElement) {
                    if (confirm(i18n.t("common_deleteEltConfirmText"))) {
                        $(this).slideUp(deleteElement);
                        mySelf.onChangeForm();
                    }
                }
            });
            return myRepeater;
        },

        registerEventsOnRepeater(pSelf, inputSelector) {
            inputSelector.off().on("keyup", function() {
                pSelf.onChangeForm();
            });
        },

        // Update subcategories list custom fields list with the repeater values
        updateAssociatedListWithRepeater(repeaterId, repeaterTextId, repeaterTextNameKey, repeaterSelectTypeKey, repeaterSelectEnumsKey) {
            let listByName = {};
            let associatedList = [];
            let associatedRepeaterVal = $("#" + repeaterId).repeaterVal();
            let repeaterList = repeaterId + "-list";
            let dataList = this.currentAssetCategory.subcategories;
            if (repeaterId === "kt_repeater_customfield") {
                dataList = this.currentAssetCategory.customFields;
            }

            if (associatedRepeaterVal && associatedRepeaterVal[repeaterList]) {
                let id, name, typec;
                for (let j = 0; j < associatedRepeaterVal[repeaterList].length; j++) {
                    id = associatedRepeaterVal[repeaterList][j][repeaterTextId];
                    name = associatedRepeaterVal[repeaterList][j][repeaterTextNameKey];
                    if (name) {
                        let item = {
                            name: name,
                            isDefault: false
                        };
                        if (id && dataList) {
                            let data = _.find(dataList, ["id", id]);
                            if (data.isDefault) {
                                item.isDefault = true;
                                item.name = data.name; // Set the subcategory key instead the displayed translation
                                if (data.typec) {
                                    item.typec = data.typec;
                                }
                                if (data.enumc) {
                                    item.enumc = data.enumc;
                                }
                            }
                            item.id = id;
                        }
                        if (!item.isDefault && repeaterSelectTypeKey) {
                            typec = associatedRepeaterVal[repeaterList][j][repeaterSelectTypeKey];
                            if (typec) {
                                item.typec = typec;
                                if (typec === "Enum") {
                                    item.enumc = associatedRepeaterVal[repeaterList][j][repeaterSelectEnumsKey];
                                    if (!item.enumc || item.enumc.length === 0) {
                                        this.sendNotification({
                                            text: i18n.t("asset_emptyCustomFieldValuesList", { name: name }),
                                            type: "error"
                                        });
                                        return null;
                                    }
                                } else {
                                    item.enumc = null;
                                }
                            }
                        }
                        associatedList.push(item);

                        // Check duplicate name
                        if (listByName[name]) {
                            // name already defined
                            let key_duplicated = "common_duplicatedEltName";
                            switch (repeaterId) {
                                case "kt_repeater_subcategory":
                                    key_duplicated = "asset_duplicatedSubcategoryName";
                                    break;
                                case "kt_repeater_customfield":
                                    key_duplicated = "asset_duplicatedCustomFieldName";
                                    break;
                            }
                            this.sendNotification({
                                text: i18n.t(key_duplicated, { name: name }),
                                type: "error"
                            });
                            return null;
                        }
                        listByName[name] = true;
                    }

                }
            }
            return associatedList;
        },

        initTypeSelect2(selector, value, index) {
            let mySelf = this;
            commonVueHelper.destroySelect2(selector);

            selector
                .select2({
                    placeholder: i18n.t("common_selectType"),
                    data: mySelf.customFieldTypesData,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(value)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    mySelf.customFields[index].typec = $(this).val();
                    let name = $(this).prop("name");

                    const nameURL = name.replace("select-type", "text-URL");

                    name = name.replace("select-type", "select-enums");

                    let urlSelector = $("#kt_repeater_customfield").find("input[name*='" + nameURL + "'][data-url*='" + index + "']");
                    let enumsSelector = $("#kt_repeater_customfield").find("select[name*='" + name + "'][data-enum*='" + index + "']");
                    if ($(this).val() === "Enum") {
                        urlSelector.parent().hide();
                        mySelf.initEnumsSelect2(enumsSelector, mySelf.customFields[index]["enumc"], index);
                        enumsSelector.parent().show();
                    } else if ($(this).val() === "URL") {
                        enumsSelector.parent().hide();
                        urlSelector.parent().show();
                    } else {
                        urlSelector.parent().hide();
                        enumsSelector.parent().hide();
                    }
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        mySelf.onChangeForm();
                    }
                });

            if (value) {
                selector.val(value).trigger({ type: "change", params: { initialInit: true } });
            }
        },

        updateTypeSelect2(selector) {
            let mySelf = this;
            const currentValue = selector.val();
            // Remove old options
            selector.empty();
            // Unit translation update
            selector
                .select2({
                    placeholder: i18n.t("common_selectType"),
                    data: mySelf.customFieldTypesData
                })
                .val(currentValue)
                .trigger("change");
        },

        initEnumsSelect2(selector, values, index) {
            let mySelf = this;
            let dataValues = [];
            if (Array.isArray(values)) {
                dataValues = values;
            }
            // Init Select2 data of categories
            commonVueHelper.destroySelect2(selector);
            selector
                .select2({
                    placeholder: i18n.t("common_enterValues"),
                    tags: true,
                    tokenSeparators: [","],
                    data: dataValues,
                    width: "100%",
                    selectOnClose: true,
                    dropdownCssClass: "enumsSelect2Dropdown"
                })
                .trigger("change")
                // emit event on change.
                .on("change", function() {
                    if (!mySelf.customFields[index].isDefault) {
                        mySelf.customFields[index].enumc = $(this).val();
                    }
                    mySelf.onChangeForm();
                });
            if (dataValues.length > 0) {
                selector.val(dataValues).trigger("change.select2");
            }
            // Force display of placeholder
            $(".select2-search__field").css("width", "500%");
        },

        updateEnumsSelect2(selector) {
            let mySelf = this;
            const currentValue = selector.val();
            selector
                .empty()
                .select2({
                    placeholder: i18n.t("common_enterValues")
                })
                .val(currentValue)
                .trigger("change");
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-sitetitle": SiteTitle
    },
    filters: {},
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditCategory", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}

.iconPreviewEdit {
    height: 45px;
    width: 45px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 300px;
}

.iconPreviewCustomEdit {
    height: 45px;
    width: 45px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 300px;
}
</style>

<style>
.select2-container--default.select2-container--disabled .select2-selection--multiple {
    border-color: rgb(235, 237, 242) !important;
}

.enumsSelect2Dropdown {
    display: none;
}
</style>
